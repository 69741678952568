export const hiddenStrategies: Record<string, string[]> = {
  '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd': [
    '0x888fC8d90177a4097e196EF6BBDc7d2e8CfFDb17',
  ],
  '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7': [
    '0xaa3Ea561a656cbE310F2E10981085DA2D989f17e',
  ],
  '0xA389f9430876455C36478DeEa9769B7Ca4E3DDB1': [
    '0x10d71115360F9129623096E8108bC6856cf86D3A',
  ],
  '0xeD8CBD9F0cE3C6986b22002F03c6475CEb7a6256': [
    '0x10d71115360F9129623096E8108bC6856cf86D3A',
  ],
  '0x454E67025631C065d3cFAD6d71E6892f74487a15': [
    '0x0Db20d1643112fA00C4D3DDB58369Ad26c1F7c1d',
  ],
};
